import Vue from 'vue'
import { mapState } from 'vuex'
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)
// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__global__) {
    Vue.__global__ = true
    Vue.mixin({
        data() {
            return {
                newCode: null,
            }
        },
        computed: {
            ...mapState({
                languages: (state) => state.master.languages,
                userPermissions: (state) => state.settings.userPermissions,
                currentPermissionCode: (state) =>
                    state.settings.currentPermissionCode,
                systemLocale: (state) => state.settings.locale,
                systemLocales: (state) => state.master.languages,
                cmsLanguages: (state) => state.cmses.selectedCurrencyLanguages,
                translationString: (state) => state.master.translationString,
                globalDefaultCurrency: (state) =>
                    state.master.globalDefaultCurrency,
                permissions: (state) => state.settings.userPermissions,
            }),
        },
        mounted() {
            // Add try catch, since first reload this.$cookie is undefined
            try {
                if (this.$cookie?.get('locale')) {
                    this.$store.dispatch(
                        'settings/locale',
                        this.$cookie?.get('locale')
                    )
                }
                if (!this.$cookie?.get('permissionCode')) {
                    this.$cookie.set('permissionCode', 'dashboard')
                }
            } catch (error) {}
        },
        methods: {
            // Check URL

            isURL(string) {
                let url
                try {
                    url = new URL(string)
                } catch (_) {
                    // If the URL creation fails, check if it starts with 'www.'
                    if (
                        string &&
                        string.startsWith('www.') &&
                        string.split('.')?.length >= 3
                    ) {
                        // Prepend 'https://' to the string and try again
                        try {
                            url = new URL('https://' + string)
                        } catch (innerError) {
                            return false
                        }
                    } else {
                        return false
                    }
                }

                const hostname = url.hostname
                const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                // Check if the protocol is 'http://' or 'https://' and if the domain is valid
                return (
                    (url.protocol === 'https:' || url.protocol === 'http:') &&
                    domainPattern.test(hostname)
                )
            },
            displayFlag(code) {
                return `fi fi-${code?.split('-')[1]?.toLowerCase()}`
            },
            displayLanguageName(code) {
                const iso = code.split('-')[0]
                const languageNames = new Intl.DisplayNames(['en'], {
                    type: 'language',
                })
                return languageNames.of(iso)
            },
            copyToClipboard(text) {
                navigator.clipboard.writeText(text)
                // notify user
                this.$toast.show(this.tl('contentCopied'))
            },
            tl(code) {
                if (Object.keys(this.translationString).length === 0) {
                    return code
                }

                if (!this.translationString[code]) {
                    const text = {}
                    const form = {}
                    for (
                        let index = 0;
                        index < this.systemLocales.length;
                        index++
                    ) {
                        text[this.systemLocales[index].code] = code
                    }
                    form.text = { ...text }
                    form.code = code
                    form.status = true
                    form.used_in = [2]

                    //  ? Uncommented this line if you wanna to add dictionary automatically, [dev mode only]

                    // if (!this.$store.state['language-strings'].newCodes.includes(code) && this.$cookie.get('token')) {
                    // 	this.$store.dispatch(
                    // 		'language-strings/addLanguageStrings',
                    // 		form
                    // 	)
                    // }
                    // this.$store.dispatch('language-strings/newCode', code);
                    return code
                }

                return this.translationString[code]
            },
            formatCurrencyJS(value, removeDecimal = false) {
                if (!value) value = 0
                const response = this.$currencyJs(parseFloat(value), {
                    separator: ',',
                    symbol: '',
                }).format()
                if (removeDecimal) {
                    return response.replace('.00', '')
                }
                return response
            },
            unFormatCurrency(amount) {
                return this.$currencyJs(amount).value
            },
            readableNumber(number) {
                if (number) {
                    return new Intl.NumberFormat().format(parseFloat(number))
                }
                return 0
            },
            // Generate the excel.
            generateExcel(fileName, excelData) {
                let columnNames = ''
                const csvString = excelData.map((item) => {
                    const tempColumnNames = []
                    const columnValues = []
                    for (const [key, value] of Object.entries(item)) {
                        columnValues.push(value)
                        tempColumnNames.push(key)
                    }
                    columnNames = tempColumnNames
                    return columnValues
                })

                columnNames = columnNames.join()
                csvString.forEach(function (row) {
                    columnNames += '\n' + row.join(',')
                })

                const hiddenElement = document.createElement('a')
                hiddenElement.href =
                    'data:text/csv;charset=utf-8,' +
                    encodeURIComponent('\uFEFF' + columnNames)
                hiddenElement.target = '_blank'
                hiddenElement.download = fileName + '.csv'
                hiddenElement.click()
            },
            dayJS(date, showTime = true) {
                if (!date) return '-'
                if (showTime) {
                    // Check if it's an ISO 8601 date
                    if (
                        this.$dayjs(date).isValid() &&
                        date?.toString()?.includes('T')
                    ) {
                        return this.$dayjs(date).format('DD-MM-YYYY HH:mm:ss') // Convert to Bangkok timezone
                    } // Define possible input formats
                    const formats = [
                        'DD/MM/YYYY HH:mm:ss',
                        'DD/MM/YYYY HH:mm',
                        'YYYY/MM/DD HH:mm:ss',
                        'YYYY/MM/DD HH:mm',
                        'DD-MM-YYYY HH:mm:ss',
                        'DD-MM-YYYY HH:mm',
                        'YYYY-MM-DD HH:mm:ss',
                        'YYYY-MM-DD HH:mm',
                    ]

                    // Try parsing the date
                    const parsedDate = this.$dayjs(date, formats, true)

                    // Validate if the parsed date is correct
                    if (!parsedDate?.isValid()) {
                        return 'Invalid Date'
                    }

                    // Determine the return format based on the original input
                    if (date?.includes('/')) {
                        if (date.length === 16) {
                            return parsedDate.format('DD-MM-YYYY HH:mm')
                        } else {
                            return parsedDate.format('DD-MM-YYYY HH:mm:ss')
                        }
                    } else {
                        return parsedDate.format(
                            date.length === 16
                                ? 'DD-MM-YYYY HH:mm'
                                : 'DD-MM-YYYY HH:mm:ss'
                        )
                    }
                } else {
                    return this.$dayjs(date).format(`DD-MM-YYYY`)
                }
            },
            getNameFromLocale(langObj) {
                // Return a default value if the language object is not provided or empty
                if (!langObj || Object.keys(langObj).length === 0) {
                    return this.tl('noDataAvailable')
                }

                // If only one language exists in the langObj object, return its value
                if (Object.keys(langObj).length === 1)
                    return langObj[Object.keys(langObj)[0]]

                //  If there are multiple languages, return its with locale or en-US
                return langObj[this.systemLocale] || langObj['en-US'] || '-'
            },
            typingSpecialNumbers(event) {
                const notAllowDash = /^[0-9.,-]/g
                this.textInputRegExgKeydown(event, notAllowDash)
            },
            allowedCharacters(event) {
                // Regular expression to allow only alphanumeric characters (A-Z, a-z, 0-9)
                const allowedCharacters = /^[a-zA-Z0-9]$/
                this.textInputRegExgKeydown(event, allowedCharacters)
            },
            rulesEmptyRequired(text = this.tl('isRequired')) {
                return [(v) => !!v || text]
            },
            isSuperAdmin() {
                return ~~this.$cookie.get('user')?.user_type === 1
            },
            disabledPlayerProfilePage() {
                if (this.isSuperAdmin()) return false
                const playerPermission = this.permissions.find(
                    (el) => el.code === 'player_list'
                )
                if (!playerPermission) return false
                if (!playerPermission.can_view) return true
                return false
            },
            goToPlayerProfile(playerId) {
                if (this.disabledPlayerProfilePage()) return null
                window.open(`/players/players/${playerId}`)
            },
            playerAge(createdAt) {
                return `${this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).diff(
                    this.$dayjs(createdAt).format('YYYY-MM-DD'),
                    'day'
                )} ${this.tl('days')}`
            },
            textInputRegExgKeydown(event, validKeyPattern = null) {
                // Valid characters for numbers and special symbols
                if (!validKeyPattern) return
                // Allow common control/navigation keys, including most standard keys
                const isControlKey =
                    event.key === 'Backspace' ||
                    event.key === 'Tab' ||
                    event.key === 'ArrowLeft' ||
                    event.key === 'ArrowRight' ||
                    event.key === 'Enter' ||
                    event.key === 'Delete' ||
                    event.key === 'Home' ||
                    event.key === 'End' ||
                    event.key === 'Escape' ||
                    event.ctrlKey ||
                    event.metaKey ||
                    event.altKey

                // If it's not a valid number key or a control/navigation key, prevent the input
                if (!(isControlKey || validKeyPattern.test(event.key))) {
                    event.preventDefault()
                }
            },
        },
    }) // Set up your mixin then
}
